import React, { useState, useEffect } from 'react';
import { iconAvatar, chart_icon } from '../../assets/Index';
import { BulletList } from 'react-content-loader';
import { apicallback } from '../../callbacks/index';
import { useStoreState } from 'easy-peasy';
import UpdateModal from './UpdateModal';
import LoginSignUp from '../../components/models/LoginSignUp';
import PageTitle from '../../components/pagetitle/PageTitle';
import { Link } from 'react-router-dom';
import AreaChart from '../../components/Chart/AreaChart';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import {
  getValueWithDecimal,
  getValueWithDecimalLocalString,
} from '../../utility/commonUtitlity';
import { check_circle_yellow, eth_front } from '../../assets/Index';
import { FiHelpCircle } from 'react-icons/fi';
import {
  MdInfo,
  MdClose,
  RiCloseLine,
  IoAddOutline,
} from '../../components/icons/Index';
import './search.css';
const Index = () => {
  const [chainId, setChainId] = useState('0');
  const [total, setTotal] = useState('0');
  const authorization = useStoreState((actions) => actions.authorization);
  const [alertInfo, setAlertInfo] = useState(false);
  const [rawChartData, setRawChartData] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [searchNfts, setSearchNfts] = useState([]);
  const [portfolio, setPortfolio] = useState({});
  const [portfolio_value, setPortfolio_value] = useState('');
  const [loader, setLoader] = useState(false);
  const [selectedObj, setSelectedObj] = useState(null);
  const [closeModal, setCloseModal] = useState(false);
  const [showModal, setModalShow] = useState(false);
  const [rarirtCheck, setRarirtCheck] = useState(false);
  const [visible, setVisible] = useState(100);
  const [searchInput, setInputSearch] = useState('');
  const [manageChart, setManageChart] = useState(null);
  const [itemsModalState, setItemsModalState] = useState(false);
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });
  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 8);
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimenion]);
  useEffect(async () => {
    if (authorization) {
      getPortfolioNftsByUser();
    }
  }, []);

  const getPortfolioSnapshot = async () => {
    //getPortfolioSnapshot
    const response = await apicallback(
      null,
      '/getPortfolioSnapshot',
      { chain: chainId },
      'private',
      authorization && authorization.token,
      'get'
    );
    if (response && response.status == 'success') {
      setRawChartData(response.data);
      let portfolioValue = [];
      let rarityValue = [];
      let dates = [];
      response.data.forEach((element) => {
        portfolioValue.push(element.total_portfolio_usd.replace('.00', ''));
        rarityValue.push(element.total_rarity_usd.replace('.00', ''));
        dates.push(moment(element.date).utc().format('MMM, YYYY'));
      });
      setManageChart({
        labels: dates.reverse(),
        data: [
          {
            name: 'Portfolio Value',
            data: portfolioValue.reverse(),
          },
          {
            name: 'Rarity Considered',
            data: rarityValue.reverse(),
          },
        ],
      });
    }
  };
  const getPortfolioNftsByUser = async (chain_id = chainId) => {
    setLoader(true);
    const response = await apicallback(
      null,
      '/getPortfolioNftsByUser',
      { chain: chain_id == 0 ? 'all' : chain_id },
      'private',
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      if (chain_id == 0) setTotal(response.data.length);
      setNfts(response.data);
      setSearchNfts(response.data);
      setLoader(false);

      let portfolio_value_new = '';
      let rarity_considered_new = '';
      if (chain_id == 2) {
        portfolio_value_new = 'portfolio_value_polygon';
        rarity_considered_new = 'rarity_considered_polygon';
      } else if (chain_id == 3) {
        portfolio_value_new = 'portfolio_value_bitcoin';
        rarity_considered_new = 'rarity_considered_bitcoin';
      } else if (chain_id == 4) {
        portfolio_value_new = 'portfolio_value_sol';
        rarity_considered_new = 'rarity_considered_sol';
      } else if (chain_id == 5) {
        portfolio_value_new = 'portfolio_value_avalanche';
        rarity_considered_new = 'rarity_considered_avalanche';
      } else if (chain_id == 1) {
        portfolio_value_new = 'portfolio_value';
        rarity_considered_new = 'rarity_considered';
      } else {
        portfolio_value_new = 'total_portfolio_values_usd';
        rarity_considered_new = 'total_rarity_value_usd';
      }

      setPortfolio({
        portfolio_value: response[portfolio_value_new],
        rarity_considered: response[rarity_considered_new],
      });
      if (rarirtCheck == true) {
        setPortfolio_value(response[portfolio_value_new]);
      } else {
        setPortfolio_value(response[rarity_considered_new]);
      }
      if (searchInput) {
        await handelSearch(searchInput);
      }
    } else {
      setLoader(false);
    }
  };

  const handelSearch = (value) => {
    setInputSearch(value);
    if (value != '') {
      const results = searchNfts.filter((alert) => {
        return (
          alert.name.toLowerCase().startsWith(value.toLowerCase()) ||
          alert.collection_name.toLowerCase().startsWith(value.toLowerCase())
        );
        // Use the toLowerCase() method to make it case-insensitive
      });
      setNfts(results);
    } else {
      setNfts(searchNfts);
    }
  };

  function toggleModalHndlr() {
    setItemsModalState(false);
  }
  const chains = {
    0: 'All',
    1: 'ETH',
    2: 'MATIC',
    3: 'BTC',
    4: 'SOL',
    5: 'AVAX',
    6: 'BASE',
  };

  function filterChartDataByChain(id) {
    let portfolioValue = [];
    let rarityValue = [];
    let dates = [];
    let portfolio_value_new = '';
    let rarity_considered_new = '';
    if (id == 0) {
      portfolio_value_new = 'total_portfolio_usd';
      rarity_considered_new = 'total_rarity_usd';
    } else if (id == 2) {
      portfolio_value_new = 'portfolio_value_polygon';
      rarity_considered_new = 'rarity_considered_polygon';
    } else if (id == 3) {
      portfolio_value_new = 'portfolio_value_bitcoin';
      rarity_considered_new = 'rarity_considered_bitcoin';
    } else if (id == 4) {
      portfolio_value_new = 'portfolio_value_sol';
      rarity_considered_new = 'rarity_considered_sol';
    } else if (id == 5) {
      portfolio_value_new = 'portfolio_value_avalanche';
      rarity_considered_new = 'rarity_considered_avalanche';
    } else {
      portfolio_value_new = 'portfolio_value';
      rarity_considered_new = 'rarity_considered';
    }

    rawChartData.forEach((element) => {
      portfolioValue.push(
        element[portfolio_value_new]
          ? element[portfolio_value_new].replace('.00', '')
          : ''
      );
      rarityValue.push(
        element[rarity_considered_new]
          ? element[rarity_considered_new].replace('.00', '')
          : 0
      );
      dates.push(moment(element.date).utc().format('MMM, YYYY'));
    });

    setManageChart({
      labels: dates.reverse(),
      data: [
        {
          name: 'Portfolio Value',
          data: portfolioValue.reverse(),
        },
        {
          name: 'Rarity Considered',
          data: rarityValue.reverse(),
        },
      ],
    });
  }

  function List(value) {
    const { img, text } = value;
    return (
      <div className="list d-flex align-items-center">
        <div className="d-flex justify-content-center align-items-center">
          <img src={img} />
        </div>
        <p>{text}</p>
      </div>
    );
  }

  return (
    <section className="tf-section tf-rank">
      <PageTitle
        meta={{
          title: 'Track your NFT Rarity with NFT Portfolio Tracker | ANZALI',
          description:
            'Track NFT portfolio to monitor real-time rarity scores across multiple markets. In-depth analysis, charts, and floor prices for informed decisions..',
        }}
      />
      {alertInfo ? (
        <div className="alertUIModal">
          <div className="position-relative mb-4">
            <h3 className="text-center mb-3">Portfolio Manager</h3>
            <button
              onClick={() => setAlertInfo(false)}
              type="button"
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
          <hr />
          <p className="text- mt-3">
            Add your NFTs to manage your portfolio and its estimated value.
          </p>
          <p className="text- mt-3">
            Hold rare NFTs? Utilize our rarity scroller to include a multiplier
            when calculating certain NFTs worth.
          </p>
          <p className="text- mt-3">
            Edit existing NFTs by clicking or tapping the row displaying that
            NFT.
          </p>
          <p className="text- mt-3">
            Your Portfolio is private and only seen by you.
          </p>
          <button
            className="sc-button style-4 btn-block mt-4"
            onClick={() => setAlertInfo(false)}
          >
            Got it
          </button>
        </div>
      ) : (
        ''
      )}
      <div className="col-sm-10 col-12 mx-auto px-0">
        <div className="px-4" id="nft-portfolio">
          {authorization && total ? (
            <>
              <div className="px-4 d-flex justify-content-between">
                <div>
                  <h2 className="text-left  mb-2">Portfolio</h2>
                  <p
                    className="h3 mt-0 mb-5 text-main cursor-pointer"
                    onClick={() => setAlertInfo(true)}
                  >
                    Learn how this works{' '}
                    <MdInfo
                      style={{ fontSize: '2rem', verticalAlign: 'bottom' }}
                    />
                  </p>
                </div>
                <div>
                  <img
                    onClick={() => {
                      if (authorization) {
                        setItemsModalState(true);
                        getPortfolioSnapshot();
                      } else {
                        setModalShow(true);
                      }
                    }}
                    src={chart_icon}
                    alt="chart portfolio cursor-pointer"
                    width="50"
                    className="pr-4"
                  />
                </div>
              </div>
              <div
                className={`d-sm-flex d-flex justify-content-sm-between flex-sm-row  flex-column`}
              >
                {nfts.length > 0 && portfolio ? (
                  <div className="portfolio-info col-sm-4 d-flex justify-content-between order-1 order-sm-2">
                    <h4>
                      Portfolio <br className="d-block d-sm-none" /> Value
                    </h4>
                    <div>
                      <p>
                        {chains[chainId] == 'All'
                          ? getValueWithDecimalLocalString(
                              rarirtCheck
                                ? portfolio?.rarity_considered || 0
                                : portfolio?.portfolio_value || 0,
                              0
                            )
                          : rarirtCheck
                          ? portfolio?.rarity_considered || 0
                          : portfolio?.portfolio_value || 0}{' '}
                        <span className="text-dark-light">
                          {chains[chainId] == 'All' ? 'USD' : chains[chainId]}
                        </span>
                      </p>
                      <div className="mt-2">
                        <input
                          className="tgl tgl-skewed"
                          id="cb3"
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked == true) {
                              setRarirtCheck(true);
                            } else {
                              setRarirtCheck(false);
                            }
                          }}
                        />
                        <label
                          className="tgl-btn"
                          data-tg-off="Rarity OFF"
                          data-tg-on="Rarity ON"
                          htmlFor="cb3"
                        ></label>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div
                  className={`text-right order-2 order-sm-1 mb-4 mb-sm-0 ${
                    nfts.length > 0 && portfolio ? 'col-sm-8' : 'col-sm-12'
                  }`}
                >
                  <button
                    className="sc-button style-4 text-dark"
                    id="create-new-post"
                    onClick={() => {
                      if (authorization) {
                        setCloseModal(true);
                        setSelectedObj(null);
                      } else {
                        setModalShow(true);
                      }
                    }}
                  >
                    <IoAddOutline
                      style={{
                        fontSize: '2rem',
                        verticalAlign: 'sub',
                        fontWeight: '600',
                      }}
                    />
                    Add NFT
                  </button>
                </div>
              </div>

              <div className="position-relative mt-5 portfolio-search float-right mb-5">
                {searchInput ? (
                  <MdClose
                    className="search-ico"
                    onClick={() => {
                      handelSearch('');
                    }}
                  />
                ) : (
                  ''
                )}
                <input
                  className={`comman-input pr-3`}
                  placeholder="Search…"
                  onChange={(e) => {
                    handelSearch(e.target.value);
                  }}
                  value={searchInput}
                />
              </div>
              <div className="col-sm-4 mt-5">
                <div className="mt-4 d-flex align-items-start justify-content-around w-100 chain-list">
                  {Object.entries(chains).map(([k, v]) => (
                    <span
                      key={k}
                      className={`${chainId == k ? 'active' : ''}`}
                      onClick={() => {
                        setChainId(k);
                        getPortfolioNftsByUser(k);
                      }}
                    >
                      {v}
                    </span>
                  ))}
                </div>
              </div>

              {loader ? (
                <BulletList />
              ) : nfts.length > 0 ? (
                <table className={`table table-striped mt-5`}>
                  <thead>
                    <tr>
                      <th scope="col" className="position-relative">
                        COLLECTION
                      </th>
                      {windowDimenion && windowDimenion.winWidth > 786 ? (
                        <th scope="col" className="text-right text-sm-center">
                          NAME
                        </th>
                      ) : (
                        ''
                      )}
                      <th
                        scope="col"
                        className="text-right text-sm-center tableMobileOnly"
                      >
                        FLOOR PRICE
                      </th>
                      <th
                        scope="col"
                        className="text-right text-sm-center tableMobileOnly"
                      >
                        RARITY
                      </th>
                      <th
                        scope="col"
                        className="text-right text-sm-center tableMobileOnly"
                      >
                        CHAIN
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {nfts.map((value, index) => (
                      <tr
                        className="cursor-pointer"
                        key={index}
                        onClick={() => {
                          setCloseModal(true);
                          setSelectedObj(value);
                        }}
                      >
                        <td className="d-flex justify-content-start align-items-center cursor-pointer onhover">
                          <img
                            src={
                              value.collection_image
                                ? value.collection_image
                                : iconAvatar
                            }
                            width="38"
                            height="38"
                            className="rounded-circle"
                          />
                          <h5 className="pl-3 text-white">
                            {value.collection_name}
                            <br className="d-block d-sm-none" />
                            <span className="d-block d-sm-none pt-2 text-main">
                              {value.name}
                            </span>
                          </h5>
                        </td>
                        {windowDimenion && windowDimenion.winWidth > 786 ? (
                          <td>
                            <h5 className="mt-3 mt-sm-3 text-right text-sm-center">
                              {value.name}
                            </h5>
                          </td>
                        ) : (
                          ''
                        )}
                        <td>
                          <h5 className="mt-3 mt-sm-3 text-right text-sm-center">
                            {value.floor_price}{' '}
                            <span className="text-dark-light">
                              {' '}
                              {chains[value.chain]}
                            </span>
                          </h5>
                        </td>
                        <td>
                          <h5 className="mt-3 mt-sm-3 text-right text-sm-center">
                            {value.rarity.replace('.00', '')}x
                          </h5>
                        </td>
                        <td>
                          <h5 className="mt-3 mt-sm-3 text-right text-sm-center">
                            {chains[value.chain]}
                          </h5>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="text-center mt-5 pt-5">
                  <p>
                    Oops! It looks like you haven't created a portfolio for{' '}
                    {chains[chainId]} chain yet.
                  </p>
                </div>
              )}
              {visible < nfts.length && (
                <div className="btn-activity mt-4 center">
                  <Link
                    to="#"
                    id="load-more"
                    className="sc-button loadmore fl-button pri-3"
                    onClick={showMoreItems}
                  >
                    <span>Load More</span>
                  </Link>
                </div>
              )}
            </>
          ) : (
            <>
              <section className="section-6">
                <div className="">
                  <div className="row">
                    <div className="col-md-5 d-flex flex-column justify-content-center">
                      <div className="first mb-4 d-flex align-items-center">
                        <img src={eth_front} className="mr-2" />
                        <span>NFT Portfolio Tracker</span>
                      </div>
                      <h4>
                        NFT Portfolio Tracker with{' '}
                        <br className="d-none d-md-block" /> Rarity Insights &
                        Floor Prices
                      </h4>
                      <p className="mt-3">
                        ANZALI is a tool that helps you track and manage your
                        NFT Portfolio (digital collectibles). It's like a
                        one-stop shop for all your NFT needs! You can use ANZALI
                        to:
                      </p>
                      <img
                        src="https://secure-assets-s3.s3.amazonaws.com/preload/Portfolio.gif"
                        className="w-100 d-block d-md-none mt-3"
                      />
                      <div className="my-0 my-md-1">
                        <List
                          img={check_circle_yellow}
                          text="View all your NFTs in one place"
                        />
                        <List
                          img={check_circle_yellow}
                          text="Find out how rare your NFTs are"
                        />
                        <List
                          img={check_circle_yellow}
                          text="Track the price of similar NFTs (floor prices)"
                        />
                        <List
                          img={check_circle_yellow}
                          text="See charts that show how your NFTs are performing"
                        />
                      </div>
                      <div className="mt-3">
                        <button
                          className="sc-button style-4 text-dark mb-4"
                          id="create-new-post"
                          onClick={() => {
                            if (authorization) {
                              setCloseModal(true);
                              setSelectedObj(null);
                            } else {
                              setModalShow(true);
                            }
                          }}
                        >
                          <IoAddOutline
                            style={{
                              fontSize: '2rem',
                              verticalAlign: 'sub',
                              fontWeight: '600',
                            }}
                          />
                          Add NFT
                        </button>
                        <button
                          className="sc-button style-1 text-dark ml-sm-3 text-main"
                          id="create-new-post"
                          onClick={() => setAlertInfo(true)}
                        >
                          <FiHelpCircle
                            style={{
                              fontSize: '2rem',
                              verticalAlign: 'sub',
                              fontWeight: '600',
                            }}
                            className="mr-2"
                          />
                          How it Works
                        </button>
                      </div>
                    </div>
                    <div className="col-md-1" />
                    <div className="col-md-6 d-none d-md-block text-center">
                      <img
                        src="https://secure-assets-s3.s3.amazonaws.com/preload/Portfolio.gif"
                        className="w-100 border-2"
                      />
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </div>
      </div>

      {closeModal ? (
        <UpdateModal
          apicallback={apicallback}
          selectedObj={selectedObj}
          authorization={authorization}
          closeModal={() => {
            setCloseModal(false);
            getPortfolioNftsByUser();
            setSelectedObj(null);
          }}
        />
      ) : (
        ''
      )}
      {authorization ? (
        ' '
      ) : (
        <LoginSignUp
          show={showModal}
          onHide={() => setModalShow(false)}
          text="Sign up or sign in to create and manage your NFT portfolio."
          url={true}
        />
      )}
      <Modal
        size="lg"
        show={itemsModalState}
        onHide={toggleModalHndlr}
        centered
        keyboard={false}
      >
        <Modal.Title className="modal-header">
          <h4 className="mb-0">Portfolio History</h4>
          <span className="headerclose-icon">
            <RiCloseLine
              onClick={toggleModalHndlr}
              className="text-neutral"
              style={{ height: '20px', width: '20px' }}
            />
          </span>
        </Modal.Title>
        <Modal.Body>
          <AreaChart
            title="Historical Portfolio Value"
            categories={manageChart ? manageChart.labels : []}
            data={manageChart ? manageChart.data : []}
            filterChartDataByChain={(v) => filterChartDataByChain(v)}
          />
        </Modal.Body>
      </Modal>
    </section>
  );
};
export default Index;
